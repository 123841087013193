import React from 'react';

import { PageProps, graphql } from 'gatsby';

import SEO from 'components/seo';

import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

import ContractAdvice from 'views/ContractAdvice';

type DataProps = {
    site: {
        buildTime: string;
    };
};

const AvisoModificacionPage: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <WithTheme>
        <Layout location={location}>
            <SEO title="Aviso de Modificación en tu Contrato de Adhesión" />
            <ContractAdvice />
        </Layout>
    </WithTheme>
);

export default AvisoModificacionPage;

export const query = graphql`
    {
        site {
            buildTime(formatString: "YYYY-MM-DD hh:mm a z")
        }
    }
`;
