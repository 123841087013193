import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '67.5%',
            marginBottom: '330px',
            marginLeft: '27.99%',
            marginTop: '237px',
            [theme.breakpoints.down(1000)]: {
                width: '70%',
                marginLeft: '22%',
            },
        },
        subGridTitle: {
            width: '68%',
            marginLeft: '27%',
            marginTop: '237px',
            [theme.breakpoints.down(1010)]: {
                width: '75%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(900)]: {
                width: '85%',
                marginLeft: '12%',
            },
            [theme.breakpoints.down(750)]: {
                marginTop: '200px',
            },

            [theme.breakpoints.down(501)]: {
                width: '70%',
                marginLeft: '21%',
                marginTop: '125px',
            },
        },
        subGridClauses: {
            width: '68%',
            marginBottom: '100px',
            marginLeft: '27%',
            marginTop: '48px',
            '& hr': {
                marginBottom: '18.3px',
                marginTop: '0px',
                background: '#3D4274',
            },
            [theme.breakpoints.down(1010)]: {
                width: '75%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(900)]: {
                width: '85%',
                marginLeft: '15%',
            },
            [theme.breakpoints.down(501)]: {
                width: '70%',
                marginLeft: '21%',
            },
        },
        subGridSignatures: {
            marginTop: '61.85px',
            '& .under': {
                border: 0,
                borderBottom: '1px solid #3D4274',
            },
            '& .signatureTextborderTop': {
                fontSize: '18.47px',
                fontWeight: 700,
                color: '#404875',
                letterSpacing: '0.06px',
                borderTop: '1px solid #3D4274',
                width: '266.17px',
                [theme.breakpoints.down(1400)]: {
                    width: '60%',
                },
            },
            '& .signatureTextMiddle': {
                fontSize: '18.47px',
                fontWeight: 700,
                color: '#404875',
                letterSpacing: '0.06px',
                paddingTop: '17px',
                marginBottom: '10px',
                [theme.breakpoints.down(1400)]: {
                    width: '60%',
                },
            },
            '& .signatureTextBottom': {
                fontSize: '18.47px',
                fontWeight: 700,
                color: '#404875',
                letterSpacing: '0.06px',
                borderBottom: '1px solid #3D4274',
                width: '266.17px',
                paddingBottom: '56.28px',
                [theme.breakpoints.down(1400)]: {
                    width: '60%',
                },
            },
            [theme.breakpoints.down(1400)]: {
                '& .signatureTextborderTop': { fontSize: '16px' },
                '& .signatureTextMiddle': { fontSize: '16px' },
                '& .signatureTextBottom': { fontSize: '16px' },
            },
            [theme.breakpoints.down(1050)]: {
                '& .signatureTextborderTop': { fontSize: '14px' },
                '& .signatureTextMiddle': { fontSize: '14px' },
                '& .signatureTextBottom': { fontSize: '14px' },
            },
            [theme.breakpoints.down(750)]: {
                '& .signatureTextborderTop': { fontSize: '16px' },
                '& .signatureTextMiddle': { fontSize: '16px', paddingTop: '17px', marginBottom: '10px' },
                '& .signatureTextBottom': { fontSize: '16px', paddingBottom: '56px' },
            },
            [theme.breakpoints.down(560)]: {
                '& .signatureTextborderTop': { fontSize: '14px' },
                '& .signatureTextMiddle': { fontSize: '14px' },
                '& .signatureTextBottom': { fontSize: '14px' },
            },
            [theme.breakpoints.down(450)]: {
                '& .signatureTextborderTop': { fontSize: '12px' },
                '& .signatureTextMiddle': { fontSize: '12px' },
                '& .signatureTextBottom': { fontSize: '12px' },
            },
        },
        titleContainer: {
            marginBottom: '133px',
            marginTop: '15px',
            '& #bot': { marginLeft: '59.5px' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '70px',
                '& #bot': { marginLeft: 0 },
            },
            [theme.breakpoints.down(750)]: { marginTop: '0px' },
            [theme.breakpoints.down(650)]: { marginTop: '25px' },
            [theme.breakpoints.down(501)]: { marginTop: '15px', marginBottom: '50px' },
        },
        title: {
            fontSize: '59.5px',
            color: '#3D4975',
            letterSpacing: '7px',
            fontWeight: 900,
            marginBottom: 0,
            [theme.breakpoints.down(1000)]: {
                fontSize: '55.24px',
                letterSpacing: '6.34px',
            },
            [theme.breakpoints.down(701)]: { fontSize: '52.24px', letterSpacing: '6px' },
            [theme.breakpoints.down(650)]: { fontSize: '45px', letterSpacing: '5.5px' },
            [theme.breakpoints.down(500)]: { fontSize: '29.85px', letterSpacing: '1.5px' },
        },
        formatoTransferImg: {
            marginTop: '66.35px !important',
            [theme.breakpoints.down(750)]: {
                '& img': {
                    width: '70% ',
                },
            },
        },
        formatoBeneficiaryImg: {
            marginTop: '66.35px !important',
            '& img': {
                width: '51% ',
            },
            [theme.breakpoints.down(750)]: {
                '& img': {
                    width: '67% ',
                },
            },
        },
        section: {
            marginBottom: '140px',
            [theme.breakpoints.down(950)]: { marginBottom: '80px' },
        },
        subtitle: {
            fontSize: '32.07px',
            color: '#3D4274',
            letterSpacing: '1.5px',
            lineHeight: '45px',
            fontWeight: 500,
            width: '100%',
            marginBottom: '63px',
            paddingRight: '40px',
            [theme.breakpoints.down(1100)]: {
                fontSize: '26px',
                letterSpacing: '1.2px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(750)]: {
                fontSize: '32px',
                letterSpacing: '1.5px',
                lineHeight: '45px',
                paddingRight: '40px',
            },
            [theme.breakpoints.down(560)]: { fontSize: '30px', lineHeight: '35px' },
            [theme.breakpoints.down(450)]: { fontSize: '20px', letterSpacing: '.7px', lineHeight: '25px' },
        },
        clause: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '1px',
            fontWeight: 900,
            marginTop: '15.5px',
            marginBottom: '34px',
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1200)]: { marginTop: '10px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px', marginTop: '5px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px', lineHeight: '20px', marginTop: '15px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px', marginTop: '10px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px', marginTop: '0px' },
        },
        subclause: {
            fontSize: '18.3px',
            color: '#3D4274',
            fontWeight: 700,
            letterSpacing: '1px',
            marginBottom: '30px',
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px' },
        },
        subcomision: {
            fontSize: '15px',
            color: '#3D4274',
            fontWeight: 900,
            marginBottom: '15px',
            letterSpacing: '1.22px',
            [theme.breakpoints.down(1050)]: { fontSize: '13px' },
            [theme.breakpoints.down(750)]: { fontSize: '15px' },
            [theme.breakpoints.down(560)]: { fontSize: '13px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px' },
        },
        comisionDescription: {
            fontSize: '18.31px',
            color: '#3D4274',
            fontWeight: 500,
            lineHeight: '26.35px',
            marginTop: '66px',
            marginBottom: '66px',
            letterSpacing: '0.6px',
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px', lineHeight: '24px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px', lineHeight: '22px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px', lineHeight: '20px' },
        },
        description: {
            fontSize: '24px',
            color: '#3D4274',
            letterSpacing: '1px',
            lineHeight: '38px',
            marginBottom: '14px',
            fontWeight: 500,
            [theme.breakpoints.down(1400)]: {
                fontSize: '22px',
                letterSpacing: '0.67px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(1050)]: {
                fontSize: '20px',
                letterSpacing: '0.59px',
                lineHeight: '25px',
            },
            [theme.breakpoints.down(750)]: {
                fontSize: '18px',
                letterSpacing: '0.52px',
                lineHeight: '21px',
            },
            [theme.breakpoints.down(600)]: {
                fontSize: '16.29px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
            [theme.breakpoints.down(450)]: {
                fontSize: '12px',
                letterSpacing: '0.40px',
                lineHeight: '13px',
            },
        },
        comision: {
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'row',
            marginBottom: '10px',
            '& .comisionText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.55px',
                marginBottom: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'clip',
            },
            '& .endText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
                marginBottom: '4.5px',
            },
            '& .endLargeText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
                marginBottom: '4.5px',
                marginLeft: '4.5px',
            },
            '& .blueSmallText': {
                alignSelf: 'flex-start',
                fontSize: '29.9px',
                fontWeight: 700,
                color: '#3D4274',
                marginBottom: '0',
                marginRight: '10px',
            },
            '& .blueText': {
                fontSize: '45.01px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '0.3px',
                marginBottom: '0',
                marginRight: '10px',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(1400)]: {
                '& .comisionText': { fontSize: '16px' },
                '& .endText': { fontSize: '16px', lineHeight: '20px' },
                '& .endLargeText': { fontSize: '16px', lineHeight: '20px' },
            },
            [theme.breakpoints.down(1050)]: {
                '& .blueSmallText': { marginRight: '5px', marginLeft: '0px', fontSize: '35px' },
                '& .blueText': { marginRight: '5px', marginLeft: '0px', fontSize: '42px' },
                '& .endLargeText': { marginLeft: '0px', fontSize: '14px' },
                '& .comisionText': { fontSize: '14px' },
                '& .endText': { fontSize: '14px' },
            },
            [theme.breakpoints.down(750)]: {
                '& .comisionText': {
                    fontSize: '16px',
                    letterSpacing: '0.55px',
                },
                '& .endText': {
                    fontSize: '16px',
                    lineHeight: '20px',
                },
                '& .endLargeText': { fontSize: '16px' },
                '& .greenText': {
                    fontSize: '23px',
                },
                '& .blueText': {
                    fontSize: '43px',
                    marginLeft: '.03',
                },
            },
            [theme.breakpoints.down(580)]: {
                '& .blueSmallText': { marginRight: '3px', marginLeft: '0px', fontSize: '30px' },
                '& .blueText': { marginRight: '3px', marginLeft: '0px', fontSize: '40px' },
                '& .endLargeText': { marginLeft: '0px', fontSize: '14px' },
                '& .comisionText': { fontSize: '14px', whiteSpace: 'break-spaces' },
                '& .endText': { fontSize: '14px' },
            },
            [theme.breakpoints.down(500)]: {
                '& .blueSmallText': { marginRight: '3px', marginLeft: '0px', fontSize: '30px' },
            },
            [theme.breakpoints.down(470)]: {
                '& .blueSmallText': { fontSize: '27px' },
                '& .blueText': { fontSize: '31px' },
                '& .endLargeText': { fontSize: '13px', letterSpacing: '.2px' },
                '& .comisionText': { fontSize: '13px', letterSpacing: '0px' },
                '& .endText': { fontSize: '13px', letterSpacing: '.2px' },
            },
            [theme.breakpoints.down(450)]: {
                '& .endLargeText': { fontSize: '10px', lineHeight: '13px', letterSpacing: '.2px' },
                '& .endText': { fontSize: '10px', lineHeight: '13px', letterSpacing: '.2px' },
            },
            [theme.breakpoints.down(430)]: {
                '& .blueSmallText': { fontSize: '26px' },
                '& .blueText': { fontSize: '30px' },
                '& .comisionText': { fontSize: '12px', letterSpacing: '0px' },
            },
            [theme.breakpoints.down(400)]: {
                '& .blueSmallText': { fontSize: '25px' },
                '& .blueText': { fontSize: '29px' },
                '& .comisionText': { fontSize: '11px', letterSpacing: '0px' },
            },
            [theme.breakpoints.down(360)]: {
                '& .blueSmallText': { fontSize: '24px' },
                '& .blueText': { fontSize: '28px' },
                '& .comisionText': { fontSize: '9.5px', letterSpacing: '0px' },
            },
        },
        comisionFlexStart: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '10px',
        },
        rightNumber: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down(900)]: { fontSize: '19px' },
            [theme.breakpoints.down(450)]: { fontSize: '17px' },
        },
        bottomText: {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            marginTop: '15px',
            marginBottom: '40px',
            width: '94%',
            '& .boldText': { fontWeight: 900 },
            [theme.breakpoints.down(1400)]: { fontSize: '16px', lineHeight: '20px' },
            [theme.breakpoints.down(1050)]: { marginLeft: '0px', fontSize: '14px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px', lineHeight: '20px', marginTop: '15px' },
            [theme.breakpoints.down(560)]: { marginLeft: '0px', fontSize: '14px' },
            [theme.breakpoints.down(450)]: {
                fontSize: '10px',
                lineHeight: '13px',
                letterSpacing: '.2px',
                marginTop: '7px',
            },
        },
        textWrapper: {
            columnCount: 2,
            columnGap: '35px',
            columnWidth: '48%',
            '& hr': {
                marginBottom: '18.3px',
                marginTop: '18.3px',
                background: '#3D4274',
            },
            [theme.breakpoints.down(900)]: { columnCount: 1 },
        },
        text: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '0.78px',
            lineHeight: '26.35px',
            marginBottom: '14px',
            fontWeight: 500,
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px', lineHeight: '20px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px', lineHeight: '22px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px', lineHeight: '20px' },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.25px',
                lineHeight: '20px',
            },
        },
        tableHeader: {
            fontSize: '17.19px',
            fontWeight: 900,
            color: '#3D4274',
            letterSpacing: '0.11px',
            lineHeight: '22px',
            padding: '6px 24px 16px 16px',
            fontFamily: 'MuseoSans, sans-serif',
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px', lineHeight: '22px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px', lineHeight: '20px' },
        },
        tableHeaderAccountFace: {
            fontSize: '14.13px',
            fontWeight: 900,
            letterSpacing: '0.05px',
            verticalAlign: 'middle',
            color: '#3D4274',
            rowSpan: 2,
            borderBottom: '2px solid #3D4274',
            fontFamily: 'MuseoSans, sans-serif',
            textAlign: 'left',
            '& .light': {
                fontSize: '14.13px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.05px',
                lineHeight: '20px',
            },
            '& .last-child': {
                width: '100%',
            },
        },
        fillingGuide: {
            marginTop: '125.2px',
        },
        subtitleAccountFace: {
            fontSize: '14.13px',
            fontWeight: 900,
            letterSpacing: '.7px',
            lineHeight: '20px',
            verticalAlign: 'middle',
            color: '#3D4274',
            width: '50%',
            borderBottom: '2px solid #3D4274',
            fontFamily: 'MuseoSans, sans-serif',
            textAlign: 'left',
            [theme.breakpoints.down(450)]: { fontSize: '13px', lineHeight: '19px' },
        },
        subtitleAccountFaceBorderless: {
            fontSize: '14.13px',
            fontWeight: 900,
            letterSpacing: '0.7px',
            lineHeight: '10px',
            verticalAlign: 'middle',
            color: '#3D4274',
            width: '50%',
            fontFamily: 'MuseoSans, sans-serif',
            textAlign: 'left',
            [theme.breakpoints.down(450)]: { fontSize: '14px', lineHeight: '19px' },
        },
        textAccountFace: {
            fontSize: '15.19px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.3px',
            marginBottom: 0,
            lineHeight: '19px',
            [theme.breakpoints.down(750)]: { fontSize: '14px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px', lineHeight: '17px' },
        },
        textAccountFaceWborder: {
            fontSize: '15.19px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.5px',
            marginBottom: 0,
            lineHeight: '19px',
            borderBottom: '2px solid #3D4274',
            [theme.breakpoints.down(450)]: { fontSize: '12px' },
        },
        tableHeaderBeneficiaries: {
            fontSize: '12px',
            fontWeight: 900,
            color: '#3D4274',
            letterSpacing: '.5px',
            lineHeight: '14px',
            verticalAlign: 'top',
            padding: '0px 4px 2px 0px',
            borderBottom: '1px solid',
            fontFamily: 'MuseoSans, sans-serif',
            '& .light': {
                fontSize: '12.73px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.8px',
                lineHeight: '15px',
            },
            [theme.breakpoints.down(450)]: {
                fontSize: '8px',
                lineHeight: '11px',
                letterSpacing: '.13px',
                '& .light': {
                    fontSize: '8.73px',
                    lineHeight: '12px',
                },
            },
        },
        tableRow: {
            fontSize: '18.34px',
            fontWeight: 500,
            color: '#3F4375',
            letterSpacing: '0.5px',
            fontFamily: 'MuseoSans, sans-serif',
            paddingTop: '8.5px',
            paddingBottom: '13px',
            [theme.breakpoints.down(1400)]: { fontSize: '16px' },
            [theme.breakpoints.down(1050)]: { fontSize: '14px' },
            [theme.breakpoints.down(750)]: { fontSize: '16px' },
            [theme.breakpoints.down(560)]: { fontSize: '14px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px' },
        },
        tableBorders: {
            borderRight: '1px solid #3D4274',
            borderBottom: '1px solid #3D4274',
            borderLeft: '0px solid #3D4274',
        },
        tableAllBordersHeaders: {
            fontFamily: 'MuseoSans, sans-serif',
            fontSize: '15px',
            color: '#3D4274',
            fontWeight: 900,
            marginBottom: '15px',
            letterSpacing: '0.8px',
            lineHeight: '18px',
            border: '1px solid #3D4274',
            textAlign: 'left',
            padding: '16px !important',
            verticalAlign: 'baseline',
            [theme.breakpoints.down(1050)]: { fontSize: '13px' },
            [theme.breakpoints.down(750)]: { fontSize: '15px' },
            [theme.breakpoints.down(560)]: { fontSize: '13px' },
            [theme.breakpoints.down(450)]: { fontSize: '12px' },
        },
        tableAllBorders: {
            border: '1px solid #3D4274',
            textAlign: 'center',
        },
        tableBordersSecond: {
            borderRight: '1px solid #3D4274',
            borderBottom: '1px solid #3D4274',
            borderLeft: '1px solid #3D4274',
        },
        tableBordersJustLeft: {
            borderLeft: '0.9px solid #3D4274',
            borderBottom: '0.9px solid #3D4274',
        },
        tableBordersJustRight: {
            borderRight: '0.9px solid #3D4274',
            borderBottom: '0.9px solid #3D4274',
        },
        borderlessLeft: {
            borderLeft: '0px solid',
            borderColor: '#3D4274',
        },
        borderlessRight: {
            borderRight: '0px solid',
            borderColor: '#3D4274',
        },
        attachedText: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '1px',
            lineHeight: '26.35px',
            fontWeight: 500,
            overflow: 'hidden',
            '& .signature': {
                textJustify: 'right',
                textAlign: 'right',
                float: 'right',
                letterSpacing: 0,
            },
            '& .sincerely': {
                textJustify: 'right',
                textAlign: 'right',
                float: 'right',
                fontSize: '15px',
                fontWeight: 700,
                letterSpacing: '3.98px',
            },
            '& .signer': {
                fontWeight: 700,
                fontSize: '13px',
                marginLeft: '45%',
                marginTop: '10px',
                paddingTop: '20px',
                lineHeight: '22.35px',
            },
            '& .signerAnnexC': {
                fontWeight: 500,
                fontSize: '15px',
            },
            '& .under': {
                border: 0,
                borderBottom: '1px solid #3D4274',
            },
            '& .underSign': {
                border: 0,
                borderTop: '1px solid #3D4274',
                fontSize: '20px',
            },
            [theme.breakpoints.down(1400)]: {
                fontSize: '16px',
                '& .sincerely': { fontSize: '13px' },
                '& .underSign': { fontSize: '18px' },
            },
            [theme.breakpoints.down(1050)]: { fontSize: '14px' },
            [theme.breakpoints.down(750)]: {
                fontSize: '16px',
                lineHeight: '25px',
                '& .signerAnnexC': { fontSize: '15px' },
                '& .underSign': { fontSize: '18px' },
                '& .sincerely': { fontSize: '13px' },
            },
            [theme.breakpoints.down(560)]: { fontSize: '14px' },
            [theme.breakpoints.down(450)]: {
                fontSize: '12px',
                lineHeight: '17px',
                '& .signerAnnexC': { fontSize: '13px' },
                '& .underSign': { fontSize: '15px' },
                '& .sincerely': { fontSize: '12px' },
            },
        },
        dottedLine: {
            borderTop: '1px dashed #3D4274',
            background: 'none !important',
        },
        link: {
            overflowWrap: 'break-word',
        },
    });
