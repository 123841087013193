import React from 'react';

import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { styles } from './ContractAdvice.style';

function ContractAdvice(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const mobileStart = useMediaQuery('(max-width:749px)');

    return (
        <div>
            <Grid container className={classes.subGridTitle}>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <p className={classes.title}>AVISO DE MODIFICACIÓN</p>
                        <p id="bot" className={classes.title}>
                            EN TU CONTRATO DE ADHESIÓN
                        </p>
                    </Box>

                    <Box className={classes.section}>
                        <p className={classes.text}>
                            Cuenta de Fondos de Pago Electrónico RECA NÚM: 15717-458-035582/04-02901-1024
                        </p>
                    </Box>
                    <Box className={classes.section}>
                        <p className={classes.text}>
                            En cumplimiento a lo establecido en las Disposiciones de Carácter General de la CONDUSEF en
                            materia de transparencia y sanas prácticas aplicables a las instituciones de tecnología
                            financiera, te notificamos que en 30 días naturales posteriores a que recibas esta
                            notificación entrarán en vigor ciertas modificaciones, las cuales se resumen más adelante,
                            realizadas a tu Contrato de adhesión de emisión y depósito mercantil de fondos de pago
                            electrónico que celebraste cuenca tecnología financiera, S.A. de C.V., Institución de fondos
                            de pago electrónico, en adelante “Cuenca”.
                        </p>
                        <p className={classes.text}>
                            El contrato mencionado se encuentra debidamente registrado en el Registro de Contratos de
                            adhesión de la CONDUSEF (“RECA”) con el número 15717-458-035582/04-02901-1024
                        </p>
                        <p className={classes.text}>
                            <u>
                                Resumen de los últimos cambios realizados al Contrato de Adhesión: Vigentes a partir del
                                23 de noviembre de 2024: 15717-458-035582/04-02901-1024
                            </u>
                        </p>
                        <p className={classes.text}>
                            Recuerda que dentro de los 30 días posteriores a que recibas esta notificación podrá dar por
                            terminado tu contrato sin responsabilidad alguna a tu cargo, y bajo las condiciones pactadas
                            previamente, debiendo cubrir, en su caso, los adeudos que se generen hasta el término de tu
                            Cuenta, sin que se te cobre alguna penalización por dicha cancelar.
                        </p>
                        <p className={classes.text}>
                            A continuación podrás revisar los cambios que se realizaron a tu contrato, de igual forma
                            podrás consultar dichos documentos dentro de la sección de legales en esta página o en tu
                            Aplicación, también podrás consultarlos cambios en el portal RECA en{' '}
                            <em>www.condusef.gob.mx</em>
                        </p>
                    </Box>
                </Grid>
            </Grid>

            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Cláusulas Modificadas</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.text}>
                        <strong>Cláusula Primera:</strong> Se homologa la definición de Cliente a la contenida en el
                        artículo 2 de la Ley Fintech y se modifica la definición de Tarjeta.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Sexta:</strong> Se actualiza la cláusula para reflejar que Cuenca no ofrece el
                        servicio de retiros de efectivo a sus clientes.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Novena:</strong> Se actualiza para reflejar que Cuenca podrá otorgar a sus
                        clientes la Tarjeta Cuenca previa solicitud expresa de su parte.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Décima Séptima:</strong> Se incluye expresamente el fundamento del
                        procedimiento en caso de robo o extravío de la Tarjeta Cuenca.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Décima Octava:</strong> Se incluye expresamente el fundamento del procedimiento
                        en caso de objeción de cargos.
                    </p>

                    <p className={classes.text}>
                        <strong>Vigentes</strong>
                    </p>
                    <p className={classes.text}>
                        - Manejo de Cuenta: ($43.10 + IVA) mensuales por concepto de manejo de cuenta.
                    </p>
                    <p className={classes.text}>
                        - Comisión por transferencia: las primeras 15 gratis, ($8.62 más IVA) cada una a partir de la
                        transferencia número 11 realizada en un mes calendario hacia una cuenta abierta en una Entidad
                        Financiera distinta a Cuenca.
                    </p>
                </Grid>
            </Grid>
            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Comisiones Vigentes</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.text}>
                        - Manejo de Cuenta: ($43.10 + IVA) mensuales por concepto de manejo de cuenta.
                    </p>
                    <p className={classes.text}>
                        - Comisión por transferencia: las primeras 15 gratis, ($8.62 más IVA) cada una a partir de la
                        transferencia número 11 realizada en un mes calendario hacia una cuenta abierta en una Entidad
                        Financiera distinta a Cuenca.
                    </p>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(ContractAdvice);
